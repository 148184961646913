import moment from 'moment';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__LIST_JOBS
} from '../../../lib/Definition';
import Engagement from '../../../lib/Engagement';
import {
  REJECTION_REASON__CANDIDATE__NOT_INTERESTED,
  STATUS_E_RECRUITER,
  STATUS_W_CANDIDATE_PERMISSION
} from '../../../dictionaries/Engagement.dic';
import {
  hoverDisplay,
  mapEngagements
} from '../../../lib/models/engagement';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import useState from '../../../lib/hooks/useState.hook';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Divider from '../../Layout/Wrappers/Divider';
import IconButton from '../../Layout/Wrappers/IconButton';
import Table from '../../Layout/Wrappers/Table';
import Loader from '../../Shared/Loader';

export function CandidateEngagementsDetails({
  candidate,
  header = true
}) {
  const [state, _updateState] = useState();
  let { loading, engagements } = state;
  async function fetchJobEngagements() {
    await _updateState({ loading: true, engagements: undefined });
    engagements = await readLoopbackRecord({
      collection: COLLECTION__ENGAGEMENTS,
      where: { candidateId: candidate.id },
      fields: [
        'id',
        'jobId',
        'candidateId',
        'lastAction',
        'stage',
        'status',
        'submitted',
        'reviewed',
        'screen1',
        'screen2',
        'screen3',
        'onsite1',
        'onsite2',
        'state',
        'statusUpdatedDate',
        'rejectionReason',
        'rejectionReasonAdditionalInfo',
        'matchType',
        'backMatchDays'
      ],
      include: [
        {
          relation: 'candidate',
          scope: {
            fields: ['id', 'accountId', 'firstName', 'lastName']
          }
        },
        {
          relation: 'job',
          scope: {
            fields: ['id', 'jobTitle', 'roles', 'employerId'],
            include: {
              relation: 'employer',
              scope: {
                fields: ['id', 'name']
              }
            }
          }
        }
      ],
      mapper: mapEngagements,
    }).catch(Core.showError);
    if (Array.isArray(engagements)) { await _updateState({ engagements }); }
    return await _updateState({ loading: false });
  }

  if (Array.isArray(engagements)) {
    engagements = engagements.sort((a, b) => {
      return String(moment(b.lastAction).toISOString()).localeCompare(moment(a.lastAction).toISOString());
    });
  }
  else if (!loading) {
    fetchJobEngagements();
  }
  return (
    <div className='bg-green-lighter h-100'>
      {header && (
        <>
          <Box row w100 className='bg-white f-lg p-2'>
            <div className='fw-600 c-cyan-darker mr-1'>
              Engagements for candidate:
            </div>
            <span className='c-black'>
              {candidate._name}
            </span>
          </Box>
          <Divider />
        </>
      )}
      {engagements && !!engagements.length ? (
        <div className="material-table m-0">
          <Table>
            <Table.Head>
              <Table.Row className='border-bottom'>
                {!Core.isLimited() && (
                  <Table.Cell>Employer</Table.Cell>
                )}
                {Core.isAdmin() && (
                  <Table.Cell>Job Title</Table.Cell>
                )}
                <Table.Cell>Last Action</Table.Cell>
                <Table.Cell>Roles</Table.Cell>
                <Table.Cell className="inline-blocks">
                  Stage&nbsp;
                  <i
                    title="Confirmation - need candidate's approval for submission&#013;Submission - 10x10 processing&#013;Review - employer reviewing resume&#013;Screen - phone screen or homework&#013;Onsite - in person interview&#013;Offer - offer discussion in progress&#013;Hire - offer accepted&#013;Guarantee - employment started"
                    className="material-icons"
                  >
                    info
                  </i>
                </Table.Cell>
                <Table.Cell className="inline-blocks">
                  Status&nbsp;
                  <i
                    title="W - waiting on the entity listed to complete the next action to move the interview forward&#013;E - the interview process was ended by the entity listed"
                    className="material-icons"
                  >
                    info
                  </i>
                </Table.Cell>
                <Table.Cell>Confirmed</Table.Cell>
                <Table.Cell>Submitted</Table.Cell>
                <Table.Cell>CV Review</Table.Cell>
                <Table.Cell>Screen</Table.Cell>
                <Table.Cell>Onsite</Table.Cell>
                <Table.Cell>State</Table.Cell>
                {Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }) && (
                  <Table.Cell>Actions</Table.Cell>
                )}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {engagements.map(engagement => (
                <Table.Row
                  key={Core.getKey()}
                  style={{ opacity: engagement.state === "Open" ? 1 : 0.4 }}
                  title={engagement.state === 'Closed' && hoverDisplay(engagement)}
                  onClick={event => Core.log({ eng: engagement })}
                  className='border-bottom bg-hover'
                >
                  {!Core.isLimited() && (
                    <Table.Cell>{engagement.employer._name}</Table.Cell>
                  )}
                  {Core.isAdmin() && (
                    <Table.Cell
                      className="ui-col"
                      title={engagement.job._jobTitle}
                    >
                      {engagement.job._jobTitle}
                    </Table.Cell>
                  )}
                  <Table.Cell title={engagement.lastAction}>
                    {engagement._lastAction}
                  </Table.Cell>
                  <Table.Cell>
                    <span
                      className={(Core.isTrusted() || Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })) ? 'anchor' : ''}
                      onClick={(Core.isTrusted() || Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })) ? ev => Core.open(`/job/view/${engagement.jobId}`) : undefined}
                    >
                      {engagement._roles}
                    </span>
                  </Table.Cell>
                  <Table.Cell>{engagement.stage}</Table.Cell>
                  <Table.Cell title={hoverDisplay(engagement)}>
                    {engagement.status}
                    <WaitingCandidatePermissionActions
                      engagement={engagement}
                      refetch={fetchJobEngagements}
                    />
                  </Table.Cell>
                  <Table.Cell title={engagement.confirmed}>
                    {engagement._confirmed}
                  </Table.Cell>
                  <Table.Cell title={engagement.submitted}>
                    {engagement._submitted}
                  </Table.Cell>
                  <Table.Cell title={engagement.reviewed}>
                    {engagement._reviewed}
                  </Table.Cell>
                  <Table.Cell>
                    <div title={`Screen 1: ${engagement.screen1}`}>
                      {engagement._screen1}
                    </div>
                    <div title={`Screen 2: ${engagement.screen2}`}>
                      {engagement._screen2}
                    </div>
                    <div title={`Screen 3: ${engagement.screen3}`}>
                      {engagement._screen3}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div title={`Onsite 1: ${engagement.onsite1}`}>
                      {engagement._onsite1}
                    </div>
                    <div title={`Onsite 2: ${engagement.onsite2}`}>
                      {engagement._onsite2}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{engagement.state}</Table.Cell>
                  {Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }) && (
                    <Table.Cell>
                      <IconButton
                        title='View engagement'
                        onClick={(event) =>
                          Core.openPopUp(`/engagement/view/${engagement.id}`)
                        }
                      >
                        <i className='material-icons'>visibility</i>
                      </IconButton>
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <>
          <div className="material-table border-bottom">
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {loading ? <Loader /> : 'No engagements'}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </>
      )}
    </div>
  );
}

export function WaitingCandidatePermissionActions({ engagement, refetch = async () => null }) {
  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  async function _closeEngagement() {
    await _updateState({ closing: true });
    let { candidate } = engagement;
    let { jobsDeclined = [] } = candidate;
    await Engagement.close({
      engagement,
      status: STATUS_E_RECRUITER,
      rejectionReason: REJECTION_REASON__CANDIDATE__NOT_INTERESTED
    }).catch(Core.showError);
    jobsDeclined.push(engagement.jobId);
    await Candidate.update(engagement.candidateId, {
      jobsDeclined
    }).catch(Core.showError);
    await refetch();
    await _updateState({ closing: false });
  }
  return (engagement.status === STATUS_W_CANDIDATE_PERMISSION) && (
    <div>
      {(!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })) && (
        <Button
          onClick={event => {
            Core.go({ to: `/candidate/edit-section/${engagement.candidateId}` })
          }}
          className='tt-unset mr-1'
          small
        >
          Edit permission
        </Button>
      )}
      <Button
        onClick={async event => {
          let { candidate } = engagement;
          let { jobsDeclined = [], jobsPermitted = [] } = candidate;
          if (jobsPermitted.includes(engagement.jobId)) {
            Core.showWarning('You already permitted the job, contact 10by10 to withdraw permission');
          }
          else if (!jobsDeclined.includes(engagement.jobId) && !state.closing) {
            await _closeEngagement();
          }
        }}
        className='tt-unset'
        disabled={!!state.closing}
        small
      >
        Decline
      </Button>
    </div>
  );
}
