import {
  mapJobVisaToCandidatePresets
} from '../../../../../../Home/mapVisaPresets.lib';

export default function evalVisaMatch({
  job = {},
  candidate = {},
}) {
  const map = {};
  mapJobVisaToCandidatePresets({ job, presets: map });
  return map.visa.includes(candidate.visa);
}
