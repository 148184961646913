import {
  useRef
} from 'react';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_EMPLOYER
} from '../../../lib/Definition';
import {
  getPdfLocalUrl
} from '../../../lib/File/getPdfLocalUrl.tool';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  JWT__SESSION_URL,
  getEmployerPendingsURL
} from '../../../lib/services/Accounts/Session.lib';
import {
  COLLECTION__EMPLOYERS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  TEN_BY_TEN__DEVELOPER_CONTACT,
  openMessageEmailPreview
} from '../../../lib/services/Email/Email.lib';
import AppBar from '../../Layout/Wrappers/AppBar';
import Button from '../../Layout/Wrappers/Button';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import StyledTooltip from '../../Layout/Wrappers/StyledTooltip';
import {
  EmployerSettingsButton
} from '../EmployerSettings';
import {
  EmployerPendings_state,
  EmployerPendings_updateState
} from './EmployerPendings';

export function EmployerPendingsAppBar({
  context,
  employer,
  pendingsCount,
  feedbacks,
  selected,
}) {
  console.debug(
    'EmployerPendingsAppBar',
    {
      context,
      employer,
      pendingsCount,
      feedbacks,
      selected,
    }
  );
  const { current = {} } = useRef({});
  const [{ contacts = [] }, _updateState] = useState();
  let pendingFeedbackSent = Object.entries(feedbacks).filter(([id, value]) => !!value.pendingsCounter).length;
  let pendingFeedbackLeft = (Number(pendingsCount) || 0) - pendingFeedbackSent;
  let state = EmployerPendings_state();
  clearTimeout(current.timeout);
  current.timeout = setTimeout(async () => {
    if (employer.id && NOT(current.error || current.busy || current.ready)) {
      current.busy = true;
      try {
        await _updateState({
          contacts: Arr(
            Obj(
              await readLoopbackRecord({
                collection: COLLECTION__EMPLOYERS,
                fields: ['id', 'emailsList'],
                limit: 1,
                where: { id: employer.id }
              })
            ).emailsList
          ).filter(
            (contact) => !!(contact.accountId)
          ).map(
            ({ accountId: id, name: label }) => ({ id, label })
          )
        });
        current.ready = true;
      }
      catch (error) {
        current.error = true;
        Core.showError(error);
      }
      current.busy = false;
    }
  });
  const _noContactFound = !Arr(contacts).length;
  return (
    <AppBar
      showBack={employer.name && Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER })}
      title={
        <>
          {employer.name}
          {Core.isEmployer() && employer.name && (
            <span className='f-lg fw-400'>
              &nbsp;(login as: {Core.getUserName()})
            </span>
          )}
          {!!pendingsCount ? (
            <span className='f-lg fw-400'>
              &nbsp;-&nbsp;
              your&nbsp;
              <span className='c-red fw-700'>
                {pendingsCount} to do&nbsp;
              </span>
              items&nbsp;
              {pendingFeedbackSent ? (
                pendingFeedbackLeft
                  ? (
                    <>
                      ({pendingFeedbackSent} done,&nbsp;
                      {pendingFeedbackLeft}&nbsp;more to go)
                    </>
                  ) : (
                    <> - All done 👍</>
                  )
              ) : (
                <></>
              )}
            </span>
          ) : (
            <></>
          )}
        </>
      }
      contentRight={
        <>
          {
            Core.isAdmin() &&
            <div
              className='d-flex flex-align-left-center px-1 py-05 pr-05 mr-1 outlined rounded-sm'
            >
              <span
                className={join([
                  'f-md fw-600 mr-1 min-w-80',
                  _noContactFound ? 'c-black-medium' : 'c-cyan-darker'
                ], ' ')}>
                Re-login as:
              </span>
              <Menu
                name={`employer_pendings_app_bar`}
                options={contacts}
                value={selected}
                onChange={async (accountId) => {
                  Core.logout({
                    redirect: await getEmployerPendingsURL({ accountId })
                  });
                }}
                disabled={_noContactFound}
                renderSelected={({ selected = {}, open }) => (
                  <Button
                    className='tt-unset space-between h-100 w-100 bg-white hint'
                    variant='outlined'
                    size='small'
                    color={open ? 'primary' : 'gray'}
                    endIcon={<i className={`material-icons c-inherit`}>{open ? 'expand_less' : 'expand_more'}</i>}
                    disabled={_noContactFound}
                  >
                    Contact
                  </Button>
                )}
                style={{ width: 155 }}
              />
            </div>
          }
          {/** LAYOUT */}
          <div className='d-flex flex-align-left-center px-1 mr-2 outlined rounded-sm'>
            <span className='f-md fw-600 c-cyan-darker mr-1'>
              Layout:
            </span>
            <Button flat
              startIcon={
                !state.hideLeft ? 'visibility' : 'visibility_off'
              }
              color={!state.hideLeft ? 'primary' : 'inherit'}
              onClick={event => {
                EmployerPendings_updateState({ hideLeft: !state.hideLeft });
              }}
              className='mr-1'
            >
              Left
            </Button>
            <Button flat
              startIcon={
                <i className='material-icons c-inherit'>{!state.hideMiddle ? 'visibility' : 'visibility_off'}</i>
              }
              color={!state.hideMiddle ? 'primary' : 'inherit'}
              onClick={event => {
                EmployerPendings_updateState({ hideMiddle: !state.hideMiddle });
              }}
              className='mr-1'
            >
              Middle
            </Button>
            <Button flat
              startIcon={
                <i className='material-icons c-inherit'>
                  {!state.hideRight ? 'visibility' : 'visibility_off'}
                </i>
              }
              color={!state.hideRight ? 'primary' : 'inherit'}
              onClick={event => {
                EmployerPendings_updateState({ hideRight: !state.hideRight });
              }}
              className='mr-1'
              disabled={
                NOT(getPdfLocalUrl(selected.candidate))
              }
            >
              Right
            </Button>
          </div>

          <EmployerSettingsButton employerId={employer.id} />
          <div className='d-flex flex-align-left-center nowrap mr-1 c-black-medium'>
            Represented by 10by10
            &nbsp;|&nbsp;
            <Button flat
              title={`Open a message box`}
              startIcon={'email'}
              onClick={event => openMessageEmailPreview({
                to: [TEN_BY_TEN_RECRUITER_EMAIL_OBJECT],
                cc: [TEN_BY_TEN__DEVELOPER_CONTACT]
              })}
            >
              recruiter@10by10.io
            </Button>
            &nbsp;|&nbsp;
            <i className='material-icons icon16'>phone</i>
            &nbsp;+1 650.336.0490
          </div>
          {Core.isEmployer() && (
            <StyledTooltip title='Done' placement='left'>
              <IconButton
                onClick={event => {
                  Core.logout({ redirect: JWT__SESSION_URL });
                }}
                icon='logout'
              />
            </StyledTooltip>
          )}
        </>
      }
    />
  );
}
