import {
  Card
} from '@mui/material';
import {
  useRef
} from 'react';
import {
  ENGAGEMENT__STATE_CLOSED
} from '../../../dictionaries/Engagement.dic';
import Core from '../../../lib/Core';
import {
  formatDateTime24hPT,
  isValidDate
} from '../../../lib/Date.lib';
import Definition, {
  ACCOUNT_ACTION__EDIT_JOB,
  DEFINITION_CATEGORY__STATE,
  STATE_ACTIVE
} from '../../../lib/Definition';
import Employer from '../../../lib/Employer';
import {
  getPdfLocalUrl
} from '../../../lib/File/getPdfLocalUrl.tool';
import {
  capitalize,
  getGravatarURL,
  NOT
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import {
  getVariableFromURLQuery
} from '../../../lib/URLQuery.lib';
import CandidateDocumentPreview from '../../Candidates/Edit/CandidateDocumentPreview';
import {
  REACT_TABLE__COLUMN_MD
} from '../../Home/useEnhancedReactTable.hook';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Page from '../../Layout/Page';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Icon from '../../Layout/Wrappers/Icon';
import Menu from '../../Layout/Wrappers/Menu';
import {
  ErrorMessage,
  SuccessMessage
} from '../../Layout/Wrappers/Message';
import NavLink from '../../Layout/Wrappers/NavLink';
import Paper from '../../Layout/Wrappers/Paper';
import StyledTooltip, {
  PLACEMENT__RIGHT_START
} from '../../Layout/Wrappers/StyledTooltip';
import TaggedValue from '../../Layout/Wrappers/TaggedValue';
import Typography from '../../Layout/Wrappers/Typography';
import Loader, {
  LoadingImage
} from '../../Shared/Loader';
import {
  ACTION_TYPE__ACCEPT,
  ACTION_TYPE__NO_ACTION,
  ACTION_TYPE__REJECT
} from './EmployerPendings.lib';
import {
  EmployerPendingsAppBar
} from './EmployerPendingsAppBar';
import {
  EmployerPendingsMiddlePanel
} from './EmployerPendingsMiddlePanel';
import {
  PendingCard
} from './PendingCard';

export function EmployerPendings_state() {
  return (Core.getKeyValue('EmployerPendings_state') || {});
}

export async function EmployerPendings_updateState(options) {
  return setTimeout(() => (Core.getKeyValue('EmployerPendings_updateState') || (async () => null))(options));
}

export default function EmployerPendings(props) {
  let { pendingsCounter = 0 } = props;
  const textAreaRef = useRef();
  let $textArea = textAreaRef.current;
  let [state, _updateState] = useState({
    hideRight: true,
  });
  Core.setKeyValue('EmployerPendings_updateState', _updateState);
  let {
    data = {},
    fetching = false,
    error = false,
    selected = {},
    feedbacks = {},
  } = state;
  let feedback = feedbacks[selected.id] || {};
  Core.setKeyValue('EmployerPendings_state', state);
  let {
    employer = {},
    processedJobs = [],
    pendingsCount = 0
  } = data;
  const engagementId = getVariableFromURLQuery('engagementId');
  const jobId = getVariableFromURLQuery('jobId');
  function _getSelected({ processedJobs = [] }) {
    let selected = {};
    const _evaluateEngagements = (engagements = [], loopMethod = 'find') => engagements[loopMethod](engagement => {
      if (loopMethod === 'map') {
        if (!engagement.pendingsCounter) {
          engagement.pendingsCounter = ++pendingsCounter;
        }
      }
      const condition = NOT(selected.id) && ((engagement.id === engagementId) || (engagement.jobId === jobId));
      if (condition) { selected = engagement; }
      console.debug('_getSelected', { engagement: engagement.id, engagementId, jobId, selected }, condition);
      return (engagementId || jobId) ? condition : true;
    });
    processedJobs.map(
      processedJob => processedJob.processedEngagements.map(
        processedEngagement => _evaluateEngagements(processedEngagement.engagements, 'map')
      )
    );
    if (!selected.id) {
      processedJobs.find(
        processedJob => _evaluateEngagements(processedJob.otherProcessedEngagements)
      );
    }
    if (!selected.id) {
      processedJobs.find(
        processedJob => _evaluateEngagements(processedJob.scheduledInterviewEngagements)
      );
    }
    if (!selected.id) {
      processedJobs.find(
        processedJob => _evaluateEngagements(processedJob.closedProcessedEngagements)
      );
    }
    return selected;
  }
  async function _fetch() {
    await _updateState({ fetching: true });
    let employerId = (
      Core.isEmployer()
        ? Core.getSession().employerId
        : getVariableFromURLQuery('employerId')
    );
    if (employerId) {
      data = await Employer.getPendings({ employerId }).catch(async error => {
        if (!!String(error || '').match(/has no associated active-engagements/i)) {
          data.employer = await Employer.get(employerId).catch(error => console.debug('Unexpected error', error));
          await _updateState({ data });
        }
        else {
          await _updateState({ error });
        }
      });
      if (data) {
        await _updateState({ data, selected: _getSelected(data) });
      }
      else {
        console.debug({ error: `Unexpected error parsing data(${data})` });
      }
    }
    else {
      await _updateState({ error: `Employer(${employerId}) no found` });
    }
    await _updateState({ fetching: false });
  }
  if (!employer.id && !fetching && !error) {
    _fetch();

    // ENABLING LEFT PANEL
    setTimeout(() => _updateState({
      hideRight: false
    }), 1000);

  }
  console.debug(
    'EmployerPendings...',
    '\n', selected
  );
  return (
    <Page
      appBar={
        <EmployerPendingsAppBar
          context={props}
          employer={employer}
          pendingsCount={pendingsCount}
          feedbacks={feedbacks}
          selected={selected}
        />
      }
    >
      {
        !!error
          ?
          <ErrorMessage>{error}</ErrorMessage>
          :
          fetching
            ?
            <Box alignCenter>
              <Loader>
                Processing
              </Loader>
            </Box>
            :
            !!processedJobs.length
              ?
              <div className='flex wrap'>

                {/** LEFT PANEL */}
                <div className={`flex-1-md w-20-md min-w-320 px-05 ${state.hideLeft ? 'd-none' : ''}`.trim()} style={{ height: 'calc(100vh - 100px)' }}>
                  <div className='p-0 h-100 scroll'>
                    {processedJobs.map((model, index) =>
                      <Card
                        key={`processed-job-${model.jobTitle}`}
                        className={`mb-1 ${index === 0 ? 'mt-05' : ''}`.trim()}
                      >
                        <EmployerProcessedJob
                          key={`processed-job-${model.jobTitle}`}
                          model={model}
                          selected={selected}
                          onClick={async selected => {
                            await _updateState({ selected, actionType: ACTION_TYPE__NO_ACTION });
                            if ($textArea) {
                              $textArea.focus();
                            }
                            else {
                              document.getElementById('feedbackInput')?.focus();
                            }
                          }}
                        />
                      </Card>
                    )}
                  </div>
                </div>

                {/** MIDDLE PANEL */}
                <EmployerPendingsMiddlePanel
                  {...({
                    employer,
                    selected,
                    textAreaRef,
                    feedback,
                    feedbacks,
                    state
                  })}
                />

                {/** RIGHT PANEL */}
                {NOT(state.hideRight) && (
                  <div
                    className={joinClassName([
                      'flex-1 min-w-320 p-05',
                      NOT(getPdfLocalUrl(selected.candidate)) && 'd-none'
                    ])}
                    style={{ height: 'calc(100vh - 100px)' }}
                  >
                    <Paper className='p-0 h-100'>
                      <CandidateDocumentPreview
                        candidate={selected.candidate}
                        jobId={selected.jobId}
                        employerId={selected.employerId}
                        onLoad={async (update) => {
                          Object.assign(selected.candidate, update);
                          await _updateState({ selected });
                        }}
                      />
                    </Paper>
                  </div>
                )}

              </div>
              :
              <SuccessMessage>
                No candidate waiting for your feedback. Thanks
              </SuccessMessage>
      }

    </Page >
  );
}

export function EmployerProcessedJob({ model, onClick, selected }) {
  let {
    jobId,
    jobTitle = '',
    jobState = 0,
    closedProcessedEngagements = [],
    otherProcessedEngagements = [],
    scheduledInterviewEngagements = [],
    processedEngagements = [],
  } = model;
  let [state, _updateState] = useState({
    expanded: (
      !selected.id ||
      (selected.jobId === jobId)
    ),
    expandedClosedEngagements: closedProcessedEngagements.find(
      ({ id }) => (selected.id === id)
    )
  });
  let { expanded = false, expandedClosedEngagements = false } = state;
  const isNotActive = (jobState !== STATE_ACTIVE);
  return (
    <Box column
      key={`processed-job-${jobTitle}`}
      className={joinClassName([
        isNotActive && 'opacity-50'
      ])}
    >
      <Box row noWrap w100 className='p-1'>
        <Typography bold cyanDark mr
          className='truncate'
        >
          {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) ? (
            <NavLink to={`/job/edit/${jobId}`} target='_blank'>
              {jobTitle}
            </NavLink>
          ) : (
            jobTitle
          )}
        </Typography>
        <Typography xs error mr
          acl={isNotActive}
        >
          ({Definition.getLabel(DEFINITION_CATEGORY__STATE, jobState)})
        </Typography>
        <Button flat small
          onClick={(event) => _updateState({ expanded: !expanded })}
          endIcon={expanded ? 'expand_less' : 'expand_more'}
          className='f-sm ml-auto'
        >
          {expanded ? 'Collapse' : 'Expand'}
        </Button>
      </Box>
      {expanded && (
        <>
          {processedEngagements.map(model =>
            <EmployerProcessedEngagement
              key={`processed-engagement-${model.label}-${model.extraLabel}`}
              model={model}
              onClick={onClick}
              selected={selected}
            />
          )}
          {!!otherProcessedEngagements.length && (
            <>
              <div className='bg-a-02 px-1'>
                <small className='c-black-medium'>
                  Waiting for candidate - {otherProcessedEngagements.length} pending
                </small>
              </div>
              {otherProcessedEngagements.map(model =>
                <EmployerReminderItem
                  key={`other-processed-engagement-${model.candidateName}-${model.jobTitle}`}
                  model={model}
                  onClick={onClick}
                  selected={selected}
                />
              )}
            </>
          )}
          {!!scheduledInterviewEngagements.length && (
            <>
              <div className='bg-a-02 px-1'>
                <small className='c-black-medium'>
                  Scheduled interviews - {scheduledInterviewEngagements.length} pending
                </small>
              </div>
              {scheduledInterviewEngagements.map(model =>
                <EmployerReminderItem
                  key={`schedule-interview-engagement-${model.candidateName}-${model.jobTitle}`}
                  model={model}
                  onClick={onClick}
                  selected={selected}
                />
              )}
            </>
          )}
          {!!closedProcessedEngagements.length && (
            <>
              <div className='bg-a-02 px-1 d-flex flex-align-left-center'>
                <small className='c-black-medium mr-auto'>
                  Archived ({closedProcessedEngagements.length})
                </small>
                <Button flat
                  onClick={async event => {
                    await _updateState({ expandedClosedEngagements: !expandedClosedEngagements });
                  }}
                  endIcon={
                    <i className='material-icons'>{expandedClosedEngagements ? 'expand_less' : 'expand_more'}</i>
                  }
                  className='f-sm'
                  small
                >
                  {expandedClosedEngagements ? 'Collapse' : 'Expand'}
                </Button>
              </div>
              {!!expandedClosedEngagements && closedProcessedEngagements.map(model =>
                <EmployerReminderItem
                  key={`closed-processed-engagement-${model.candidateName}-${model.jobTitle}`}
                  model={model}
                  onClick={onClick}
                  selected={selected}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}

export function EmployerProcessedEngagement({ model, onClick, selected }) {
  let {
    engagements: reminderItems = [],
    extraLabel = '',
    label = ''
  } = model;
  return (
    <div className='flex-col'>
      <div className='flex-col bg-a-02 px-1'>
        <small className='c-cyan-darker'>{label}</small>
        <small>{extraLabel}</small>
      </div>
      <Menu.List className='p-0'>
        {reminderItems.map(item => {
          item.processLabel = model.label;
          return (
            <EmployerReminderItem
              key={`employer-reminder-item-${item.label}-${item.candidateName}`}
              model={item}
              onClick={onClick}
              selected={selected}
            />
          );
        })}
      </Menu.List>
    </div>
  );
}

export function EmployerReminderItem({ model, onClick, selected }) {

  let {
    id,
    candidateName,
    label,
    statusPart,
    date,
    agoDate,
    agoDateB,
    ratingFlag,
    submittedLabel,
    processLabel,
    candidate,
    pendingsCounter,
    state,
    closed,
    submitted,
    rejectionReason,
    rejectionReasonAdditionalInfo
  } = model;

  const _itemKey = `pending-item-candidate-${id}`;

  async function _onClick(event) {
    onClick(model);
    try {
      document.getElementById(_itemKey).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    catch { }
  }

  // to auto-select first item on loading page
  if (!EmployerReminderItem.autoselect && ((!selected.id) || (selected.id === id))) {
    EmployerReminderItem.autoselect = model;
    setTimeout(() => !model.thread && _onClick());
  }

  const _loading = !!((EmployerPendings_state() || {}).feedbacks || {})[id]?.loading;

  return (
    <Menu.Item
      key={_itemKey}
      id={_itemKey}
      className='p-0'
      onClick={_onClick}
    >
      <PendingCard
        avatarClassName={pendingsCounter ? 'bg-red-lighter c-black-darker fw-600' : ''}
        model={{
          image: (
            !!model.thread
              ? (
                <i className='material-icons c-cyan mr-1 icon24'>task_alt</i>
              )
              : pendingsCounter
                ? ''
                : getGravatarURL(candidate.email)
          ),
          abbr: pendingsCounter || candidate._abbr,
          title: (
            <Box row noWrap>
              <span
                id='emp_pen__candidate_section'
                className='mr-auto d-flex flex-align-left-center'
              >
                <span
                  className='truncate'
                  style={{ maxWidth: REACT_TABLE__COLUMN_MD }}
                >
                  {candidateName}
                </span>
                {ratingFlag}
                {submittedLabel && (
                  <small
                    id='emp_pen__submitted_label'
                    className='c-black-medium fw-500'
                  >
                    &nbsp;-&nbsp;
                    {submittedLabel}
                  </small>
                )}
              </span>
              <Box row noWrap flex1 alignRight
                title={Core.isAdmin() && submitted && (
                  <>
                    <Typography italic alignRight className='f-sm c-gray-medium'>Admin only</Typography>
                    <TaggedValue tag='Submitted' value={isValidDate(submitted) && formatDateTime24hPT(submitted)} />
                    <TaggedValue tag='Closed' value={isValidDate(closed) && formatDateTime24hPT(closed)} />
                    <TaggedValue tag='Reason' value={rejectionReason} />
                    <TaggedValue
                      tag='Info'
                      value={
                        (
                          trim(rejectionReason) !==
                          trim(rejectionReasonAdditionalInfo)
                        ) &&
                        rejectionReasonAdditionalInfo}
                    />
                  </>
                )}
                placement={PLACEMENT__RIGHT_START}
                style={{ minHeight: 20 }}
              >
                {_loading
                  ? (
                    <div className='mr-2'>
                      {LoadingImage}
                    </div>
                  ) : (
                    !!Str(processLabel).match(/feedback/i) && (
                      <div id='emp_pen__thumb_actions'>
                        <Icon
                          acl={NOT(
                            !!model.thread ||
                            !!Str(model.thread?.actionType).match(
                              ACTION_TYPE__ACCEPT
                            )
                          )}
                          icon='thumb_down'
                          className={joinClassName([
                            'c-black-medium icon16 ml-1',
                            NOT(
                              Str(model.thread?.actionType).match(
                                ACTION_TYPE__REJECT
                              )
                            ) && 'material-icons-outlined'
                          ])}
                        />
                        <Icon
                          acl={NOT(
                            !!model.thread ||
                            !!Str(model.thread?.actionType).match(
                              ACTION_TYPE__REJECT
                            )
                          )}
                          icon='thumb_up'
                          className={joinClassName([
                            'c-black-medium icon16 ml-1',
                            NOT(
                              Str(model.thread?.actionType).match(
                                ACTION_TYPE__ACCEPT
                              )
                            ) && 'material-icons-outlined'
                          ])}
                        />
                      </div>
                    )
                  )}
              </Box>
            </Box>
          ),
          sub1:
            <>
              {label}
              {agoDateB ? ` ${agoDateB}d ago` : !!agoDate && ` ${agoDate}d ago`}
              {date ? ` (${date})` : ''}
            </>,
          sub2: trim(statusPart) && (
            <div className='d-flex flex-align-left-center'>
              <span className='mr-1'>
                {capitalize(statusPart)}
              </span>
              {Core.isAdmin() && (state === ENGAGEMENT__STATE_CLOSED) && NOT(closed) && (
                <StyledTooltip
                  title={(
                    <>
                      Admin:<br />
                      The <b>closed</b> date is missing.<br />
                      The value shown instead is <b>statusUpdatedDate</b>.
                    </>
                  )}
                >
                  <i className='material-icons icon16'>info</i>
                </StyledTooltip>
              )}
            </div>
          ),
          selected: selected.id === id
        }}
      />
    </Menu.Item>
  );
}
